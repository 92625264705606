<div class='bems-loader-wrapper' id='bems-loader-wrapper'>
    <div class='bems-loader'>
      <svg class='circular' viewbox='25 25 50 50'>
        <circle class='path' cx='50' cy='50' fill='none' r='20' stroke-miterlimit='10' stroke-width='7'></circle>
      </svg>
    </div>
</div>
<div id="MENU" class="noneselect">
    <div class="background-menu mb-0">
        <img routerLink="/commu/overview" style="cursor: pointer;" src="assets/images/cusc_logo.png" class="img-fluid" alt="Responsive image">
        <span class="box-icon">
          <a routerLink="/commu/overview" style="cursor: pointer;">
            <img class="home-icon" alt="brand" src="assets/images/home_icon.png">
          </a>
          <a *ngIf="userAdmin" type="button" style="cursor: pointer;" data-toggle="dropdown" aria-expanded="false" data-offset="10,20">
            <img class="home-icon" alt="brand" src="assets/images/settings.png">
          </a>
          <div class="dropdown-menu">
            <a *ngIf="privilege" class="dropdown-item" href="#/admin/system-status">System Status</a>
            <a *ngIf="privilege" class="dropdown-item" href="#/admin/component-setting">Component Setting</a>
            <a *ngIf="privilege" class="dropdown-item" href="#/admin/user-management">User Management</a>
            <a *ngIf="!privilege" class="dropdown-item" (click)="logInForm()">Sign in</a>
            <a *ngIf="privilege" class="dropdown-item" (click)="logOut()">Sign out</a>
          </div>
          <a data-toggle="modal" data-target="#aboutModal" style="cursor: pointer;">
            <img class="info-icon" alt="brand" src="assets/images/info_icon.png">
          </a>
        </span>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="aboutModal" tabindex="-1" role="dialog" aria-labelledby="aboutModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body box-about">
        <div class="row box-about-1">
          <div class="col-6 box-about-1-logo-1">
            <img class="aboutCuscLogoImg" src="assets/images/cusc-Logo.png">
          </div>
          <div class="col-6 box-about-1-logo-2">
            <img class="aboutERCLogoImg" src="assets/images/ERC-Logo.png">
            <span>ได้รับการสนับสนุนงานงบประมาณจากกองทุนพัฒนาไฟฟ้า สำนักงานคณกรรมการกำกับกิจการพลังงาน พ.ศ. 2562</span>
          </div>
        </div>
        <div class="row box-about-2">
          <div class="col-5 box-about-2-details">
            <span>ChulaSmart: <b>Smart Community</b></span>
            <span>version 1.0.10</span>
            <span class="m-1">Release date: 21 March 2023</span>
          </div>
          <div class="col-7 box-about-2-power">
            <span>Power by:</span>
            <img class="aboutChulaLogoImg" src="assets/images/chula-Logo.png">
            <img class="aboutERILogoImg" src="assets/images/ERI-Logo.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthService } from './auth.service'
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { CoreService, ConfigService } from '../service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private http: HttpClient,
    private authService: AuthService,
    
    private core: CoreService

  ) {
    super(router, keycloak);
  }
  
  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (route.url[0].path === 'home') {
        if (!this.authenticated) {
          resolve(true);
        } else {
          resolve(true);
        }
      } else if (route.url[0].path === 'admin') {
        if (!this.authenticated) {
          this.keycloakAngular.login();
          resolve(false);
        } else {
          resolve(true);
        }
      } else if (route.url[0].path === 'owner') {
        if (!this.authenticated) {
          this.keycloakAngular.login();
          resolve(false);
        } else {
          resolve(true);
        }
      }
    })
  }


}
import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor() { }

  loader0show(){ 
    $('#bems-loader-wrapper').show();
  }

  loader0hide() {
    setTimeout(()=>{  $('#bems-loader-wrapper').hide(); }, 1000);
  }


}
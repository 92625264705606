import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth_keycloak/auth.service';
import { KeycloakService } from 'keycloak-angular'
import { Router } from '@angular/router';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  mobile: boolean = false;
  constructor(
    private keyCloakServ: KeycloakService,
    private authService: AuthService,
    private router: Router,
  ) { }

  public user_role: any = false;
  public user;
  public privilege;

  page: string = 'overview';
  time: any;
  date: any;
  userAdmin: any = false;

  ngOnInit(): void {
    setInterval(() => {
      this.time = moment().format('HH:mm');
      this.date = moment().format('D MMMM YYYY');
    }, 1000);

    if (window.screen.width <= 414) { } else { this.stickyNavbar(); }

    if (window.screen.width > 720) { this.userAdmin = true; }

    this.keyCloakServ.isLoggedIn().then(
      (res) => {
        if(res){ 
          this.authService.initializeUserOptions() 
        }else{
          this.authService.clearStorage();
        } 
      }
    );

    setTimeout(() => {
      this.user_role = this.authService.check_role(localStorage.getItem("usty"));
      this.user = localStorage.getItem("usnm");
      this.privilege = this.user !== null;
    }, 1000);
  }

  stickyNavbar() {
    $(window).scroll(function () {
      let targetScroll = $('.header-line-hight-top').position().top + $('.header-line-hight-top').outerHeight(true);
      if ($(window).scrollTop() <= targetScroll) {
        $(".bg-nav").removeClass("nav-fixedtop");
      } else {
        $(".bg-nav").addClass("nav-fixedtop");
      }
    });
  }

  logOut() {
    this.privilege = false;
    this.authService.deauthen();
  }


  public logInForm() {
    // let redirect_url = this.router.url;
    // localStorage.setItem('redirect_url', redirect_url);
    // this.router.navigate(['/login']);
    this.keyCloakServ.login();
  }

  clickClose() {
    $(".collapse").removeClass("show");
    $(".collapse").addClass("collapsing");
    $(".bg-menu").removeClass("display-block");
    $(".bg-menu").addClass("display-none");
  }

  clickMenu() {
    $(".bg-menu").removeClass("display-none");
    $(".bg-menu").addClass("display-block");
  }

}

import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { CoreService } from './core.service';
import { ConfigService } from './config.service';

declare var IEEE1888: any;
@Injectable({
  providedIn: 'root'
})
export class ConnectStorageService {

  constructor(
    private http: HttpClient,
    
    private core: CoreService,
  ) { }

  pad(num) {
    let norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  }

  isoDate(local) {
    var tzo = -local.getTimezoneOffset();
    var sign = tzo >= 0 ? '+' : '-';
    return local.getFullYear()
      + '-' + this.pad(local.getMonth() + 1)
      + '-' + this.pad(local.getDate())
      + 'T' + this.pad(local.getHours())
      + ':' + this.pad(local.getMinutes())
      + ':' + this.pad(local.getSeconds())
      + sign + this.pad(tzo / 60)
      + ':' + this.pad(tzo % 60);
  }

  toArray(ngObj) {
    var newArray = [];
    for(var key in ngObj) if(typeof ngObj[key].name === 'string') newArray.push(ngObj[key].name);
    return newArray;
  }

  checkStorage(pointList,timeStart,timeNow,response){
        // let now = new Date();
        // let start = new Date(now.getTime()-(15*60*1000));
        let now = timeStart;
        let start = timeNow;
        let storage = new IEEE1888();
        storage.setURL(this.core.getStorageUrl());
        storage.setKeyAttr("attrName","time");
        storage.setKeyAttr("gt",this.isoDate(start));
        storage.setKeyAttr("lt",this.isoDate(now));
        storage.setPointID(pointList);
        storage.setCallbackFunc(response);
        storage.query();
    }
//   checkStorage(pointList,response){
//     let now = new Date();
//     let start = new Date(now.getTime()-(15*60*1000));
//     let storage = new IEEE1888();
//     storage.setURL(this.core.getStorageUrl());
//     storage.setKeyAttr("attrName","time");
//     storage.setKeyAttr("gt",this.isoDate(start));
//     storage.setKeyAttr("lt",this.isoDate(now));
//     storage.setPointID(pointList);
//     storage.setCallbackFunc(response);
//     storage.query();
//   }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth_keycloak/auth.guard';
import { AuthLocalGuard } from './auth_local/auth-local.guard';

import { LoginComponent } from './login/login.component';

let guard_type = localStorage.getItem('guard_type');
let guard_aerobase = [AuthGuard];
let guard_local = [AuthLocalGuard];
let switch_guard;

if (guard_type == null) {
  switch_guard = guard_local;
} else if (guard_type == 'guard-local') {
  switch_guard = guard_local;
} else {
  switch_guard = guard_aerobase;
}


const routes: Routes = [
  {
    path: 'commu',
    loadChildren: () =>
      import('./commu/commu.module').then((m) => m.CommuModule),
      data: { roles: ["public"]}
  },
  {
    path: 'owner',
    loadChildren: () =>
      import('./owner/owner.module').then((m) => m.OwnerModule),
    canActivate: switch_guard,
    data: { roles: ["owner"]}
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: switch_guard,
    data: { roles: ["admin"]}
  },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/commu', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';

import { HeaderComponent } from './../header/header.component';
import { FooterComponent } from './../footer/footer.component';



@NgModule({
  declarations: [HeaderComponent,FooterComponent],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    OrderModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    OrderModule,
    HeaderComponent,
    FooterComponent
  ]
})

export class SharedModule { }

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('ustk');

        if (token) {
            request = request.clone({ headers: request.headers.set('token', token) });
        }
        if (!request.headers.has('Content-Type')) {
            if (!(request.body instanceof FormData)) {
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event.status);
                }
                return event;
            }));
    }
}